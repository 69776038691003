'use client'

import { Button, Menu } from '@mantine/core'
import Link from 'next/link'
import { useParams } from 'next/navigation'
import { Icon } from '~/components/icons/icon'
import { IconName } from '~/components/icons/sprite'
import { countries } from '~/data/countries'
import { defaultCountry, SupportedCountries } from '~/data/enums'
import { useQS } from '~/hooks/use_qs'
import { activeQS } from '~/utils/qs'

export function CountrySelector() {
  const [qs] = useQS()
  const params = useParams<{ country: SupportedCountries }>()
  return (
    <Menu>
      <Menu.Target>
        <Button
          size="xs"
          color="blue"
          leftSection={
            <Icon name={countries[params.country || defaultCountry].flag} />
          }
          rightSection={<Icon name="chevron-down" />}
        >
          {countries[params.country || defaultCountry].name}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {Object.values(countries).map(({ name, slug }, index) => {
          return (
            <Menu.Item
              key={index}
              component={Link}
              href={`/${slug}/${activeQS(qs)}`}
            >
              <div className="flex items-center gap-1">
                <Icon name={`${slug}-flag` as IconName} />
                <span>{name}</span>
              </div>
            </Menu.Item>
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}
