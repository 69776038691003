import { useQueryStates } from 'nuqs'
import { queryStringParsers } from '~/schemas/qs_parsers'

/**
 * hook to incapsulate usage of query strings
 * @returns
 */
export function useQS() {
  return useQueryStates(queryStringParsers)
}
